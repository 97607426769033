<template>
  <div>
    <div class="odm-flex odm-vertical-list mt55 ml30">
      <div class="odm-flex">
        <div class="odm-flex odm-vertical-list">
          <img src="../../assets/images/download_001.png" style="width: 460px;height: 260px;">
          <img src="../../assets/images/download_002.png" style="width: 460px;height: 260px;">
        </div>
        <div class="odm-flex odm-vertical-list ml100 odm-align-center odm-vertical-center">
          <div class="title-name">(小学语数英)</div>
          <img src="../../assets/images/xiaoxue.png" class="code_qr">
          <div class="sm-txt">扫描微信二维码立即登录</div>
          <img @click="goDownload(0)" src="../../assets/images/dowl_btm.png" style="width: 207px;height: 43px;margin-top: 33px">
        </div>
      </div>

      <div class="odm-flex mt100">
        <div class="odm-flex odm-vertical-list">
          <img src="../../assets/images/download_003.png" style="width: 460px;height: 260px;">
          <img src="../../assets/images/download_004.png" style="width: 460px;height: 260px;">
        </div>
        <div class="odm-flex odm-vertical-list ml100 odm-align-center odm-vertical-center">
          <div class="title-name">(初中语数英)</div>
          <img src="../../assets/images/chuzhong.png" class="code_qr">
          <div class="sm-txt">扫描微信二维码立即登录</div>
          <img @click="goDownload(1)" src="../../assets/images/dowl_btm.png" style="width: 207px;height: 43px;margin-top: 33px">
        </div>
      </div>

      <div class="odm-flex mt100">
        <div class="odm-flex odm-vertical-list">
          <img src="../../assets/images/download_005.png" style="width: 460px;height: 260px;">
          <img src="../../assets/images/download_006.png" style="width: 460px;height: 260px;">
        </div>
        <div class="odm-flex odm-vertical-list ml100 odm-align-center odm-vertical-center">
          <div class="title-name">(高中语数英)</div>
          <img src="../../assets/images/gaozhong.png" class="code_qr">
          <div class="sm-txt">扫描微信二维码立即登录</div>
          <img @click="goDownload(2)" src="../../assets/images/dowl_btm.png" style="width: 207px;height: 43px;margin-top: 33px">
        </div>
      </div>
    </div>
    <el-dialog
        title="温馨提示"
        v-model="dialogVisibleOut"
        width="30%"
        :before-close="handleClose">
      <span>确定退出登录吗？</span>
      <template #footer>
    <span class="dialog-footer">
      <el-button @click="dialogVisibleOut = false">取 消</el-button>
      <el-button type="primary" @click="logoutOk()">确 定</el-button>
    </span>
      </template>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data () {
    return {
    }
  },
  created() {
  },
  mounted () {
  },
  methods: {
    goDownload(type){
      switch (type){
        case 0:
          location.href="http://www.hzcjyvip.cn/apk/xiaoxue.apk"
          break
        case 1:
          location.href="http://www.hzcjyvip.cn/apk/chuzhong.apk"
          break
        case 2:
          location.href="http://www.hzcjyvip.cn/apk/gaozhong.apk"
          break
      }
    }
  }
}
</script>

<style>
.title-name{
  font-size: 19px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #3D3D3D;
  line-height: 26px;
}
.code_qr{
  width: 162px;
  height: 162px;
  margin-top: 15px;
}
.sm-txt{
  font-size: 17px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #999999;
  line-height: 13px;
  margin-top: 24px;
}
</style>
