<template>
  <div class="odm-flex odm-align-center odm-height-100 odm-width-100" :class="dialogVisible?'over-no':'over-ok'">
    <div class="odm-flex odm-vertical-list odm-width-70 odm-white">
      <div class="odm-flex mt15  odm-width-100 odm-vertical-center">
        <div class="odm-flex odm-vertical-list odm-width-30 ">
          <div class="odm-flex odm-vertical-center">
            <img @click="returnClick()"  src="./images/logo.png" style="width:220px;height: 40px;cursor:pointer;">
          </div>
<!--          <div class="odm-flex mt12">-->
<!--            <div class="xia_dneg" @click="loginClick(0)" v-if="false">-->
<!--              【微信登录】-->
<!--            </div>-->
<!--            <div class="xia_dneg" @click="loginClick(1)" v-if="false">-->
<!--              【会员登录】-->
<!--            </div>-->
<!--            <div class="xia_dneg" @click="loginClick(2)" v-if="false">-->
<!--              【手机注册】-->
<!--            </div>-->

<!--          </div>-->
        </div>
        <div style="cursor:pointer;" @click="chooseTag(index)" class="odm-flex  odm-width-10" v-for="(item,index) in tagName" :key="index">
           <div :class="index == thisChooseTag?'tag-name-choose':'tag-name-no'" class="text-singline">{{item.name}}</div>
        </div>
        <div style="display: flex;flex: 1;justify-content: flex-end;margin-right: 30px">
          <div style="cursor:pointer;"  class="layout-zc-dl odm-flex odm-vertical-center odm-align-center" @click="loginClick(5)" v-if="!islogin">登录</div>
          <div v-if="false" style="cursor:pointer;"  class="layout-zc-dl odm-flex odm-vertical-center odm-align-center"  @click="loginClick(2)">注册</div>
          <img style="width: 40px;height: 40px;" v-if="islogin" src="../../assets/images/head.png" />
          <div  style="cursor:pointer;margin-left: 15px" class="xia_dneg odm-vertical-center odm-flex" @click="logout" v-if="islogin">
            退出登录
          </div>
        </div>
      </div>
<!--      <el-carousel  class="mt15 ml15 mr15" height="110px">-->
<!--        <el-carousel-item v-for="(item,index) in this.banners" :key="index" >-->
<!--         <img :src="item" style="width: 100%;height: 100%">-->
<!--        </el-carousel-item>-->
<!--      </el-carousel>-->
      <div style="margin-top: 30px" class="odm-flex odm-vertical-list" v-if="thisChooseTag!=4">
      <div class="odm-flex odm-wrap" >
        <div style="cursor:pointer;" v-for="(item,index) in classList" :key="index">
          <div @click="classClick(item,index)" :class="classTag == index?'choose-ok':'choose-no'" class="mt15 mb15">{{item.class_name}}</div>
        </div>
      </div>
      <div class="odm-flex odm-vertical-list" >
<!--        <div class="odm-flex ml20 mt15 mb15">-->
<!--&lt;!&ndash;          <div class="bbfl text-singline">版本分类:</div>&ndash;&gt;-->
<!--          <div style="cursor:pointer;" v-for="(item,index) in banbenList.values" class="odm-flex odm-vertical-center odm-align-center" :key="index">-->
<!--            <div class="text-singline" :class="banbenTag == index?'banb-choose':'banb-nochoose'" @click="banbenClick(index)">-->
<!--              {{item.value}}-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="line"></div>-->
<!--        <div class="odm-flex ml20 mt15 mb15">-->
<!--        <div class="bbfl text-singline">年级分类:</div>-->
<!--        <div style="cursor:pointer;" v-for="(item,index) in nianjiList.values" class="odm-flex odm-vertical-center  odm-align-center" :key="index">-->
<!--          <div class="text-singline" :class="nianjiTag == index?'banb-choose':'banb-nochoose'" @click="nianjiClick(index)">-->
<!--            {{item.value}}-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
      </div>
        <div class="infinite-list-wrapper" style="overflow:auto">
          <div v-infinite-scroll="load" infinite-scroll-disabled="disabled">
            <div class="odm-width-100 odm-flex odm-wrap">
              <div style="cursor:pointer;" v-for="(item,index) in bookList" :key="index" :class="this.thisChooseTag==5?'odm-width-20':'odm-width-18'">
                <el-image :class="this.thisChooseTag==5?'book-image1':'book-image'" :src="item.material_image" referrerpolicy="no-referrer" @click="bookClickItem(item)"></el-image>
                <div style="color: #292929;font-size: 15px;font-family: 'PingFangSC-Medium';margin-top: 5px;max-width: 150px;margin-left: 12px">
                  {{item.material_name}}
                </div>
              </div>
            </div>
          </div>
          <div  class="odm-flex odm-vertical-center odm-align-center mt15 mb30" v-if="loading&&thisChooseTag!=4">加载中...</div>
          <div class="odm-flex odm-vertical-center odm-align-center mt15 mb30" v-if="noMore&&thisChooseTag!=4">没有更多了</div>
      </div>
      </div>
      <down-load v-if="thisChooseTag==4"></down-load>
      <div class="odm-flex odm-vertical-center odm-align-center odm-width-100"  style="margin: 30px">
        <div class="odm-flex odm-vertical-center odm-vertical-center">上海侠健网络科技有限公司 备案号：<a href="https://beian.miit.gov.cn/" target="_blank">沪ICP备20018468号</a></div>
      </div>
    </div>
   <login-register ref="login_register" @result="result"></login-register>
  </div>
</template>

<script>
import loginRegister from "@/View/components/loginRegister";
import downLoad from "@/View/components/downLoad";

export default {
  components:{
    loginRegister,
    downLoad
  },
  data () {
    return {
      tagName: [
          // {name:'小学',id:14,
          //   fenlei:[
          //       ]
          // },
          {name:'初中',id:15,
            fenlei:[
            ]
          },
          {name:'高中',id:16,
            fenlei:[
            ]},
          {name:'课外课堂',id:3,
            fenlei:[
            ],
          },
          // {name:'App下载',id:0},
          // {name:'播放历史',id:0}
          ],
      thisChooseTag: 0,
      classTag:0,
      banbenTag:0,
      nianjiTag:0,
      banners: [],
      count: 10,
      loading: false,
      banbenList:{},
      nianjiList:{},
      classList:[],
      islogin:false,
      requestBookList: {
        classId: '',
        pageNum: 1,
        pageSize: 20
      },
      requestBookListHistory:{
        userkey:'',
        type:1,
        pageNum:1,
        limit:20,
      },
      requestTop:{
        "pid":15
      },
      bookList:[],
      noMore:false,
      userkey:''
    }
  },
  computed: {
    disabled () {
      return this.loading || this.noMore
    }
  },
  activated() {
    if (!this.$route.meta.isBack) {
      // 操作，从其他页面进入时，可以操作恢复默认状态、请求接口。。。
      // 页码重置查询
      console.log('需要重置')
      //获取banner
      // this.getBanner()
      this.bookList=[]
      this.requestBookList.pageNum=1
      // this.getKmTop2List()
    } else {
      // 详情页返回操作，比如页面位置
      //  页码不重置
      console.log('不需要重置')
    }
    this.$route.meta.isBack=false
  },
  beforeRouteEnter(to, from, next) {
    console.log('....',from.name)
       if (from.name === 'VideoPlayer' ) {
           to.meta.isBack = true
         } else {
           to.meta.isBack = false
         }
       next()
  },
  created() {
    var userMsg = localStorage.getItem('token');
    console.log('用户信息',userMsg)
    if(userMsg){
      this.islogin = true;
      this.userkey = JSON.parse(userMsg).userkey;
    }else{
      this.islogin = false;
      this.userkey = '';
    }
    // this.getBanner()
    this.bookList=[]
    this.requestBookList.pageNum=1
    this.getKmTop2List()
    //再之后就是在每个页面的方法执行后写上
    // window._czc.push(['_trackEvent', '进入', '进入语数英同步课堂首页']);
    //这个方法一定要在方法执行代码下面执行，不然在ios中，点击事件就会直接中断。
  },
  methods: {
    returnClick(){
      this.getBanner()
      this.bookList=[]
      this.requestBookList.pageNum=1
      this.getKmTop2List()
    },
    getPlayerHistory(){
      this.bookList = [];
      this.loading = true
      this.requestBookListHistory.userkey = this.userkey;
      this.request({
        url: this.api.apiArray.COURS_MY_PALY,
        params:this.requestBookListHistory
      }).then(res => {
        console.log( res, '课程列表接口返回：')
        this.loading = false
        if(res.data.code == 200) {
            this.bookList = res.data.data;
        }
      })
    },
    logout(){
     this.$refs.login_register.logout()
    },
    result(json){
      console.log(json,'结果')
      if(json == 0){
        this.islogin = false
      }else {
        this.islogin = true;
      }
    },
    loginClick(type){
      this.$refs.login_register.openLogin(type);
    },
    classClick(item,index){
      this.bookList = []
      this.requestBookList.classId =  item.id
      this.classTag = index
      this.requestBookList.pageNum=1
      this.getJcLb()
    },
    banbenClick(index){
      this.banbenTag = index
      this.bookList=[]
      this.requestBookList.pageNum=1
      this.changeNB()
      this.getJcLb()
    },
    //切换年级和版本
    changeNB(){
      const attrData = []
      let art = {
        attr_id:this.banbenList.id,
        attr_value_id:this.banbenList.values[this.banbenTag].id
      }
      attrData.push(art)
      let art1 = {
        attr_id:this.nianjiList.id,
        attr_value_id:this.nianjiList.values[this.nianjiTag].id
      }
      attrData.push(art1)
      console.log('mmp',attrData)
      this.requestBookList.attr= JSON.stringify(attrData)
    },
    nianjiClick(index){
      this.nianjiTag = index
      this.bookList=[]
      this.requestBookList.pageNum=1
      this.changeNB()
      this.getJcLb()
    },
    load () {
      console.log('加载更多')
      if(this.requestBookList.pageNum>1){
        this.getJcLb();
      }
    },
    chooseTag (tag) {
      this.thisChooseTag = tag
      console.log('狗日的',this.thisChooseTag)
      if(this.thisChooseTag == 5){
        this.getPlayerHistory();
        return
      }
      if(this.thisChooseTag<4) {
        this.classTag = 0
        this.banbenTag = 0
        this.nianjiTag = 0
        this.bookList=[]
        this.requestBookList.pageNum=1
        this.getKmTop2List()
      }
    },
    getBanner(){
      this.request({
        url: this.api.apiArray.AD_GET_AD,
        params: { ad_index: 'haibao' }
      }).then(res => {
        let list = res.data.data
        for (var i = 0; i < list.length; i++) {
          this.banners.push(list[i].url)
        }
        console.log(this.banners, 'getBanner返回：')
      })
    },
    //分类接口
    getKmTop2List(){
      this.requestTop.pid = this.tagName[this.thisChooseTag].id
      this.requestNoKey({
        url: this.api.apiArray.TOP_CLASS,
        data: this.requestTop
      }).then(res => {
        this.classList = res.data.data
        this.requestBookList.classId = this.classList[this.classTag].id
        this.getJcLb()
      })
    },
    getJcLb(){
      this.loading = true
      this.requestNoKey({
        url: this.api.apiArray.MATERIAL_BOOK,
        data: this.requestBookList
      }).then(res => {
        this.loading = false
        console.log('雅儿', res.data.data.toalPage)
        if(res.data.status == 200) {
          if(this.bookList.length<res.data.data.toalPage){
            this.bookList = this.bookList.concat(res.data.data.dataList)
            this.noMore = false;
            if(res.data.data.dataList.length<20){
              return
            }
            //加载逻辑
            this.requestBookList.pageNum= this.requestBookList.pageNum+1;
          }else{
            this.noMore = true;
          }
        }
      })
    },
    bookClickItem(item) {
      console.log('书籍点击', item)
      this.$router.push({
        path: '/VideoPlayer',
        query: {
          book_id: item.id
        }
      });
    }
  },
}
</script>

<style>
.book-image{
  width: 100%;
  height: 275px;
  opacity: 1;
  background: white;
  padding: 16px;
}
.book-image1{
  width: 100%;
  height: 170px;
  opacity: 1;
  background: white;
  padding: 16px;
}
.layout-zc-dl{
  opacity: 1;
  border-radius: 25px;
  background: #44A4CD;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 2px;
  padding-bottom: 2px;
  text-align: center;
  margin-left: 5px;
  color: #FFFFFF;
  height: 30px;
}

.choose-ok{
  font-size: 20px;
  font-family: Source Han Sans CN Regular, Source Han Sans CN Regular-Regular;
  font-weight: 400;
  text-align: center;
  color: #44a4cd;
  letter-spacing: 0px;
  margin-left: 25px;
}
.choose-no{
  font-size: 20px;
  font-family: Source Han Sans CN Regular, Source Han Sans CN Regular-Regular;
  font-weight: 400;
  text-align: center;
  color: #333333;
  letter-spacing: 0px;
  margin-left: 25px;
}
.line{
  border: 0.5px dashed #D7D7D7;
  margin-left: 17px;
  margin-right: 17px;
}
.bbfl{
  font-size: 20px;
  font-family: Source Han Sans CN Regular, Source Han Sans CN Regular-Regular;
  font-weight: bold;
  text-align: left;
  color: #333333;
  letter-spacing: 0px;
}
.title_name{
  opacity: 1;
  font-size: 17px;
  font-family: YRDZST Bold, YRDZST Bold-Bold;
  font-weight: 700;
  text-align: center;
  color: #44a4cd;
}
.xia_dneg{
  opacity: 1;
  font-size: 22px;
  font-family: Source Han Sans CN Regular, Source Han Sans CN Regular-Regular;
  font-weight: 400;
  text-align: left;
  color:blue;
  letter-spacing: 0px;
}
.tag-name-no{
  opacity: 1;
  font-size: 20px;
  font-family: Source Han Sans CN Medium, Source Han Sans CN Medium-Medium;
  font-weight: 500;
  text-align: right;
  color: #333333;
  letter-spacing: 0px;
}
.tag-name-choose{
  opacity: 1;
  font-size: 20px;
  font-family: Source Han Sans CN Medium, Source Han Sans CN Medium-Medium;
  font-weight: 500;
  text-align: right;
  color:#44A4CD;
  letter-spacing: 0px;
}
.banb-choose{
  background: #44a4cd;
  border-radius: 3px;
  color: white;
  height: auto;
  margin-left: 12px;
  font-size: 18px;
  padding-top: 1px;
  padding-bottom: 1px;
  padding-left: 2px;
  padding-right: 2px;
}
.banb-nochoose{
  font-size: 18px;
  font-family: Source Han Sans CN Regular, Source Han Sans CN Regular-Regular;
  font-weight: bold;
  text-align: left;
  color: #333333;
  letter-spacing: 0px;
  margin-left: 12px;
}
</style>
